import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaGithub, FaLinkedin } from 'react-icons/fa'
import { FooterContainer, SocialMedia, WebsiteRights, SocialIcons, SocialIconLink} from './footerElements'

const Footer = () => {

    return (
        <FooterContainer>
            <SocialMedia>
                <SocialIcons>
                    <SocialIconLink href="https://www.facebook.com/vasekirschner/" target="_blank" arial-label="Facebook"><FaFacebook/></SocialIconLink>
                </SocialIcons>
                <SocialIcons>
                    <SocialIconLink href="https://www.instagram.com/vasekirschner/" target="_blank" arial-label="Instagram "><FaInstagram/></SocialIconLink>
                </SocialIcons> 
                <SocialIcons>
                    <SocialIconLink href="https://twitter.com/vaclavkirschner" target="_blank" arial-label="Twitter"><FaTwitter/></SocialIconLink>
                </SocialIcons>
                    <SocialIcons>
                        <SocialIconLink href="/" target="_blank" arial-label="Github"><FaGithub/></SocialIconLink>
                    </SocialIcons>
                    <SocialIcons>
                        <SocialIconLink href="/" target="_blank" arial-label="Linkedin"><FaLinkedin/></SocialIconLink>
                    </SocialIcons>
            </SocialMedia>
            <WebsiteRights>
                © Václav Kirschner, {new Date().getFullYear()}
            </WebsiteRights>
        </FooterContainer>
    );
}

export default Footer