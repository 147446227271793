import React, { useState } from 'react';
import { Link } from 'gatsby'
import { FaBars, FaTimes } from 'react-icons/fa'

import { NavbarContainer, NavLogo, HamburgerMenuIcon, NavMenu, NavItem, NavLink } from "./navbarElements";
import logo_white_frame from  "../../images/logo.svg";

const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    
    return (
        <>
            <NavbarContainer click={ click }>
                <Link to="/"><NavLogo src={ logo_white_frame }/></Link>
                <HamburgerMenuIcon onClick={ handleClick }>
                    { click ? <FaTimes /> : <FaBars />}
                </HamburgerMenuIcon>
                <NavMenu onClick={ handleClick } click={click}>
                    <NavItem>
                        <NavLink to="/">home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/blog">blog</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/photo-gallery">photo gallery</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/about">about</NavLink>
                    </NavItem>
                </NavMenu>
            </NavbarContainer>
        </>
    );
}

export default Navbar