import styled from "styled-components"

export const FooterContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: solid;
  border-width: 2px;
  border-color: black;
  height: 80px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;

  @media screen and (max-width: 960px) {
    height: 48px;
  }
`

export const SocialMedia = styled.section`
    display: flex;
    align-items: center;
    max-width: 1024px;
    margin-top: 5px;
    
`

export const WebsiteRights = styled.small`
  color: black;
`

export const SocialIcons = styled.div`
  display: table;
  align-items: center;
  padding: 0 2px 0 2px;
`

export const SocialIconLink = styled.a`
  color: black;
  font-size: 20px;

  &:hover {
    color: red;
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 960px) {
    font-size: 17px;
  }
`