import { Link } from "gatsby"
import styled from "styled-components"

export const NavbarContainer = styled.nav`
    align-items: center;
    display: flex;
    height: 80px;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    border-bottom: solid;
    border-color: black;
    border-width: 2px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 998;

    @media screen and (max-width: 960px) {
      height: 48px;
      justify-content: center;
    }
`

export const NavLogo = styled.img`
   cursor: pointer;
   display: block;
   height: 35px;
   width: 176px;
   z-index: 999;
`

export const HamburgerMenuIcon = styled.div`
    display: none;
  
    @media screen and (max-width: 960px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.2rem;
      cursor: pointer;
    }
`

export const NavMenu = styled.ul`
    list-style: none;
    display: flex;
    padding: 0px;
    margin-left: 45%;
    
    @media screen and (max-width: 960px) {
      display: flex;
      flex-direction: column;
      position: absolute;
      align-items: center;
      top: 48px;
      height: 100vh;
      width: ${({ click }) => (click ? "100%" : "0")};
      opacity: ${({ click }) => (click ? "1" : "0")};
      top: ${({ click }) => (click ? "48px" : "-1000px")};
      transition: ${({ click }) => (click ? "opacity 0.2s ease" : "")};
      transition: ${({ click }) => (click ? "width 0.2s ease" : "")};
      padding: 0;
      background-color: white;
      margin: 0 auto;
  }
`

export const NavItem = styled.li`

`

export const NavLink = styled(Link)`
    color: black;
    text-decoration: none;
    padding: 1rem;
    color: ${({ click }) => (click ? "red" : "black")};
    
    &:hover {
        color: #ff0000;
        transition: all 0.3s ease;
    }

    @media screen and (max-width: 960px) {
      text-align: center;
      display: table;
      padding: 2rem;
    }

    @media screen and (max-height: 370px) {
      text-align: center;
      display: table;
      padding: 1rem;
    }
`